<template>
  <div class="right_side">

    <div class="section_payment_breadcrumbs">
      <span>{{ $t('profile.main_page') }}</span>
      <span>{{ $t('profile.partnership_program') }}</span>
    </div>

    <div class="section_header mt-3">
      <span>{{ $t('profile.ref_withdrawals.payments') }}</span>
    </div>

    <div class="mt-4" v-if="history == null">
      <span>{{ $t('profile.ref_withdrawals.no_payments') }}</span>
    </div>

    <table class="table table-striped" v-else>
      <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">{{ $t('profile.ref_withdrawals.amount') }}</th>
        <th scope="col">{{ $t('profile.ref_withdrawals.payment_system') }}</th>
        <th scope="col">{{ $t('profile.ref_withdrawals.requisites') }}</th>
        <th scope="col">{{ $t('profile.ref_withdrawals.status') }}</th>
        <th scope="col">{{ $t('profile.ref_withdrawals.date') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="r in history" v-bind:key="r.id">
        <td>Заявка № {{ r.id }}</td>
        <td>$ {{ r.value }}</td>
        <td>{{ r.paymentSystem }}</td>
        <td>{{ r.requisites }}</td>
        <td>{{ r.createdDate | moment("DD.MM.YYYY") }}</td>
        <td>
          <span v-if="r.status === 'NEW'" class="badge badge-pill badge-primary">{{ $t('profile.ref_withdrawals.status_new') }}</span>
          <span v-if="r.status === 'ON_PAYMENT'" class="badge badge-pill badge-info">{{ $t('profile.ref_withdrawals.status_on_payment') }}</span>
          <span v-if="r.status === 'ERROR'" class="badge badge-pill badge-danger">{{ $t('profile.ref_withdrawals.status_error') }}</span>
          <span v-else-if="r.status === 'PAYED'" class="badge badge-pill badge-success">{{ $t('profile.ref_withdrawals.status_payed') }}</span>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<script>


import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'ReferralHistory',

  components: {},

  data: function () {
    return {

      history: null


    }
  },

  computed: {
    ...mapGetters(['token']),

  },

  mounted() {
    axios.get('/api/profile/referral/withdrawals', {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }).then(
        response => {
          this.history = response.data
        }
    );
  }
}
</script>
